/**
|--------------------------------------------------
| @vunv

|--------------------------------------------------
*/

import { is_empty } from "./validate"

// use: get_unique(arr, 'id')
const list_unit = [{
	"text": "Kg",
	"value": 1
}, {
	"text": "Lít",
	"value": 2
}, {
	"text": "Cái",
	"value": 3
}, {
	"text": "Thùng",
	"value": 4
}, {
	"text": "Chai",
	"value": 5
}, {
	"text": "Bộ",
	"value": 6
}, {
	"text": "Can",
	"value": 7
}, {
	"text": "Công",
	"value": 8
}, {
	"text": "DVT",
	"value": 9
}, {
	"text": "ml",
	"value": 10
}]

const LIST_UNIT = {
	1: "Kg",
	2: "Lít",
	3: "Cái",
	4: "Thùng",
	5: "Chai",
	6: "Bộ",
	7: "Can",
	8: "Công",
	9: "DVT",
	10: "ml",
}

const render_unit = (unit) => {
	const found = list_unit?.filter(item => item.value === unit)
	if (is_empty(found[0])) {
		return "Cái"
	}
	return found.map(item => item.text)
}

const list_plans = [
	{
		text: "Gói dùng thử",
		value: 1
	},
	{
		text: "Gói tiết kiệm",
		value: 2
	},
	{
		text: "Gói tiêu chuẩn",
		value: 3
	},
	{
		text: "Gói nâng cao",
		value: 4
	},
]

const TEXT_PLAN = {
	1: "Gói dùng thử",
	2: "Gói tiết kiệm",
	3: "Gói tiêu chuẩn",
	4: "Gói nâng cao",
}

const list_payment_method = [{
	'text': 'Tiền mặt',
	'value': 1,
}, {
	'text': 'Chuyển khoản ngân hàng',
	'value': 3,
}, {
	'text': 'Thanh toán POS',
	'value': 4,
}
]
const BANK_OPTION = [
	3,4
]

const STATUS_ORDER = {
	"CANCEL": [0, -2],
	"APPOINTMENT": 1,
	"QUOTE": 2,
	"FIXING": 3,
	"FINISH": 4,
	"COMPLETED": 5,
	"IN_COMPLETE": [1, 2, 3, 4],
	"IS_EXPORT": [2, 3, 4],

}


export { list_unit, render_unit, list_plans, list_payment_method, TEXT_PLAN, LIST_UNIT, STATUS_ORDER, BANK_OPTION }
